<template>
  <AlertManagment :default_filter="$route.params.filter" />
</template>

<script>
  import AlertManagment from '@/views/AlertManagment.vue';

  export default {
    components: {
      AlertManagment
    },
    name: 'AlertManagementByFilter'
  };
</script>